import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import "./styles/index.css"

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import Testimonies from './pages/Testimonies';
import Offer from './pages/Offer';
import Price from './pages/Price';
import AskOffer from './pages/AskOffer';
import PageVideograf from './pages/PageVideograf';
import Portofolio from './pages/Portofolio';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import TestimoniesUsers from './pages/TestimoniesUsers';
import AccountSettings from './pages/AccountSettings';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      <Route path='/' element={<><Login /></>} />
      <Route path='/dashboard' element={<><Dashboard /></>} />
      <Route path='/users' element={<><Users /></>} />
      <Route path='/home' element={<><Home /></>} />
      <Route path='/about-me' element={<><AboutMe /></>} />
      <Route path='/testimonies' element={<><Testimonies /></>} />
      <Route path='/offer' element={<><Offer /></>} />
      <Route path='/price_page' element={<><Price /></>} />
      <Route path='/askoffer' element={<><AskOffer /></>} />
      <Route path='/portofolio' element={<><Portofolio /></>} />
      <Route path='/blog' element={<><Blog /></>} />
      <Route path='/pagevideograf' element={<><PageVideograf /></>} />
      <Route path='/contact' element={<><Contact /></>} />
      <Route path='/footer' element={<><Footer /></>} />
      <Route path='/testimonies-users' element={<><TestimoniesUsers /></>} />
      <Route path='/account-settings' element={<><AccountSettings /></>} />
      <Route path='/*' element={<><Dashboard /></>} />
    </Routes>
    
  </Router>
);
