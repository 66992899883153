import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Contact.module.css';
import 'react-toastify/dist/ReactToastify.css';
import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'
import { FaHouse } from "react-icons/fa6";

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Contact = (props) => {

   const[isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState(null);
   
   const [contactLocation, setContactLocation] = useState("");
   const [contactEmail, setContactEmail] = useState("");
   const [contactPhone, setContactPhone] = useState("");
   const [contactKeyWords, setContactKeyWords] = useState("");
   const [contactImage, setContactImage] = useState("");

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setData(jsonData);
            
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "contact_location" ? (
                  setContactLocation(obiect["value_cp"])
               ) : obiect["key_cp"] === "contact_email" ? (
                  setContactEmail(obiect["value_cp"])
               ) : obiect["key_cp"] === "contact_phone" ? (
                  setContactPhone(obiect["value_cp"])
               ) : obiect["key_cp"] === "key_words_contact" ? (
                  setContactKeyWords(obiect["value_cp"])
               ) : obiect["key_cp"] === "contact_image" && (
                  setContactImage(obiect["value_cp"])
               )
            ))
         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const update_contact = async (e, type) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "", value;
      
      if(type === "email"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "contact_email" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = contactEmail;
      }
      else  if(type === "locatie"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "contact_location" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = contactLocation;
      }
      else  if(type === "telefon"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "contact_phone" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = contactPhone;
      }

      else  if(type === "keyWords"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "key_words_contact" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = contactKeyWords;
      }

      else  if(type === "image"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "contact_image" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = contactImage;
      }

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: value,
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Valoare modificata cu succes!");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }
      setIsLoading(false);
   };

   const handleChange= (e, type) => {
      if(type === "email"){
         const { value } = e.target;
         setContactEmail(value);
      }
      else if(type === "locatie"){
         const { value } = e.target;
         setContactLocation(value);
      }
      else if(type === "telefon"){
         const { value } = e.target;
         setContactPhone(value);
      }
      else if(type === "keyWords"){
         const { value } = e.target;
         setContactKeyWords(value);
      }
      else if(type === "image"){
         const { value } = e.target;
         setContactImage(value);
      }
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Contact</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica Contact Imagine</h3>
                  <form onSubmit={(e) => update_contact(e, "image")}>
                     <div className={styles_static.row}>
                        <p>Url:</p>
                        <input type="text" defaultValue={contactImage} onChange={(e) => handleChange(e, "image")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica imagine</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Contact Email</h3>
                  <form onSubmit={(e) => update_contact(e, "email")}>
                     <div className={styles_static.row}>
                        <p>Email:</p>
                        <input type="text" defaultValue={contactEmail} onChange={(e) => handleChange(e, "email")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica email</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Contact Locatie</h3>
                  <form onSubmit={(e) => update_contact(e, "locatie")}>
                     <div className={styles_static.row}>
                        <p>Locatie:</p>
                        <input type="text"  defaultValue={contactLocation} onChange={(e) => handleChange(e, "locatie")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica locatie</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Contact Telefon</h3>
                  <form onSubmit={(e) => update_contact(e, "telefon")}>
                     <div className={styles_static.row}>
                        <p>Telefon:</p>
                        <input type="text" defaultValue={contactPhone} onChange={(e) => handleChange(e, "telefon")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica telefon</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Cuvinte Cheie</h3>
                  <form onSubmit={(e) => update_contact(e, "keyWords")}>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie:</p>
                        <input type="text" defaultValue={contactKeyWords} onChange={(e) => handleChange(e, "keyWords")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica cuvinte cheie</button>
                     </div>
                  </form>
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Contact;