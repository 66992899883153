import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import styles from '../styles/Login.module.css';
import 'react-toastify/dist/ReactToastify.css';

import {getCookieValue, deleteCookie} from '../utils/Cookie';
import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'

import BACKEND_URL from '../utils/env';

import Loading from '../utils/loading';

const Login = (props) => {

   const [data, setData] = useState(null);
   const [formData, setFormData] = useState({ username: '', password: '' });
   const[isLoading, setIsLoading] = useState(false);

   const navigate = useNavigate();
   

   const handleSubmit = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      if (formData.username.trim() === '' || formData.password.trim() === '') {
         notify_error("Toate casetele sunt obligatorii!");
         return;
      }
      try {
         const response = await fetch(BACKEND_URL + '/login', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               username: formData.username,
               password: formData.password,
            }),
            credentials: 'include',
         });
         if (response.ok) {

            const jsonData = await response.json();

            Cookies.set("auth_token", jsonData.auth_token, { expires: 0.3 })

            notify_success("Autentificat cu succes!");
            window.location.href = './dashboard';
         }
         else {
            notify_error("Username si/sau parola gresita!");
            setFormData(prevState => ({
               ...prevState,
               password: ''
            }));
         }

      } catch (error) {
         console.error('Eroare:', error);
         setFormData(prevState => ({
            ...prevState,
            password: ''
         }));
      }

      setIsLoading(false);
   };
   useEffect(() => {
      setIsLoading(true);

      const checkToken = async () => {
         const response = await fetch(BACKEND_URL + '/me', {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Autentificat cu succes!");
            navigate("/dashboard");
         }
         else {
            notify_error("Autentificare necesara!");
            setFormData(prevState => ({
               ...prevState,
               password: ''
            }));
         }
      };
      if(getCookieValue("auth_token") !== null )
         if(getCookieValue("auth_token").trim() !== "")
            checkToken();
      
      setIsLoading(false);
   }, []);

   const handleChange = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value,
      });
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles.main}>
            <form className={styles.form} onSubmit={handleSubmit}>
               <h1>Admin Panel</h1>

               <br />

               <div className={styles.row}>
                  <p>Username</p>
                  <input type="username" name="username" value={formData.username} onChange={handleChange} />
               </div>

               <div className={styles.row}>
                  <p>Password</p>
                  <input type="password" name="password" value={formData.password} onChange={handleChange} />
               </div>

               <div className={styles.row}>
                  <button type='submit'>Login</button>
               </div>
            </form>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Login;