import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import Collapsible from 'react-collapsible';
import moment from 'moment';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Portofolio.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { FaHouse } from "react-icons/fa6";

import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Blog = (props) => {

   const formatDateForInput = (dateString) => {
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
   };

   const [data, setData] = useState(null);
   const [dataContentPage, setDataContentPage] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const [blogDate, setBlogDate] = useState({});
   const [blogImage, setBlogImage] = useState({});
   const [blogVideo, setBlogVideo] = useState({});
   const [blogTitle, setBlogTitle] = useState({});
   const [blogSubTitle, setBlogSubTitle] = useState({});
   const [blogContent, setBlogContent] = useState({});
   const [blogSubContent, setBlogSubContent] = useState({});
   const [blogUrl, setBlogUrl] = useState({});
   const [blogPriority, setBlogPriority] = useState({});
   const [blogKeyWord, setBlogKeyWord] = useState({});   

   const [addBlogDate, setAddBlogDate] = useState(new Date().toISOString().slice(0,10));
   const [addBlogImage, setAddBlogImage] = useState("");
   const [addBlogVideo, setAddBlogVideo] = useState("");
   const [addBlogTitle, setAddBlogTitle] = useState("");
   const [addBlogSubTitle, setAddBlogSubTitle] = useState("");
   const [addBlogContent, setAddBlogContent] = useState("");
   const [addBlogSubContent, setAddBlogSubContent] = useState("");
   const [addBlogUrl, setAddBlogUrl] = useState("");
   const [addBlogPriority, setAddBlogPriority] = useState(0);
   const [addBlogKeyWord, setAddBlogKeyWord] = useState("");

   const [keyWords, setKeyWords] = useState(undefined);

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if (await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchDataContentPage = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setDataContentPage(jsonData);

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "key_words_blog" && (
                  setKeyWords(obiect["value_cp"])
               )
            ))

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchDataContentPage();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/blog');
            const jsonData = await response.json();
            setData(jsonData);

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const add_article = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      const response = await fetch(BACKEND_URL + '/blog?', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            blog_date: addBlogDate,
            blog_image: addBlogImage,
            blog_video: addBlogVideo,
            blog_title: addBlogTitle,
            blog_subtitle: addBlogSubTitle,
            blog_content: addBlogContent,
            blog_subcontent: addBlogSubContent,
            blog_url: addBlogUrl,
            blog_key_word: addBlogKeyWord,
            priority: addBlogPriority,
         }),
         credentials: 'include',
      });
      if (response.ok) {
         window.location.reload(false);
      }
      else {
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   };

   const delete_article = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa stergi articolul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/blog?uuid=' + e.target.value, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            window.location.reload(false);
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const visible_article = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa modifici vizibilitatea articolul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/blog/visible?uuid=' + e.target.value, {
            method: 'put',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Vizibilitate modificata cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const update_article = async (e) => {
      setIsLoading(true);
      var answer = window.confirm("Esti sigur ca vrei sa modifici articolul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/blog?uuid=' + e.target.value, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               blog_date: blogDate[e.target.value],
               blog_image: blogImage[e.target.value],
               blog_video: blogVideo[e.target.value],
               blog_title: blogTitle[e.target.value],
               blog_subtitle: blogSubTitle[e.target.value],
               blog_content: blogContent[e.target.value],
               blog_subcontent: blogSubContent[e.target.value],
               blog_url: blogUrl[e.target.value],
               blog_key_word: blogKeyWord[e.target.value],
               priority: blogPriority[e.target.value],
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Articol modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeAdd = (e, type, content) => {

      if (type === "blogDate") {
         const { value } = e.target;
         console.log(value);
         setAddBlogDate(value);
      }
      if (type === "blogImage") {
         const { value } = e.target;
         setAddBlogImage(value);
      }
      if (type === "blogVideo") {
         const { value } = e.target;
         setAddBlogVideo(value);
      }
      else if (type === "blogTitle") {
         const { value } = e.target;
         setAddBlogTitle(value);
      }
      else if (type === "blogSubTitle") {
         const { value } = e.target;
         setAddBlogSubTitle(value);
      }
      else if (type === "blogContent") {
         setAddBlogContent(content);
      }
      else if (type === "blogSubContent") {
         setAddBlogSubContent(content);
      }
      else if (type === "blogUrl") {
         const { value } = e.target;
         setAddBlogUrl(value);
      }
      else if (type === "blogPriority") {
         const { value } = e.target;
         setAddBlogPriority(value);
      }
      else if (type === "blogKeyWord") {
         const { value } = e.target;
         setAddBlogKeyWord(value);
      }
   };

   const handleChangeStatic = (e, uuid, type, content) => {

      if (type === "blogDate") {
         const { value } = e.target;
         console.log(value);
         blogDate[uuid] = value;
      }
      if (type === "blogImage") {
         const { value } = e.target;
         blogImage[uuid] = value;
      }
      if (type === "blogVideo") {
         const { value } = e.target;
         blogVideo[uuid] = value;
      }
      if (type === "blogTitle") {
         const { value } = e.target;
         blogTitle[uuid] = value;
      }
      if (type === "blogSubTitle") {
         const { value } = e.target;
         blogSubTitle[uuid] = value;
      }
      else if (type === "blogContent") {
         blogContent[uuid] = content;
      }
      else if (type === "blogSubContent") {
         blogSubContent[uuid] = content;
      }
      else if (type === "blogUrl") {
         const { value } = e.target;
         blogUrl[uuid] = value;
      }
      else if (type === "blogPriority") {
         const { value } = e.target;
         blogPriority[uuid] = value;
      }
      else if (type === "blogKeyWord") {
         const { value } = e.target;
         blogKeyWord[uuid] = value;
      }
   };

   const change_key_words = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      dataContentPage && dataContentPage.map((obiect, index) => (
         obiect["key_cp"] === "key_words_blog" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: keyWords,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeKeyWords = (e, uuid) => {
      const { value } = e.target;
      setKeyWords(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Blog</h1>
               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Modifica cuvinte cheie blog principal</h3>
                  <form onSubmit={change_key_words}>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie:</p>
                        <input type="text" name="url" defaultValue={keyWords} onChange={(e) => handleChangeKeyWords(e)} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica cuvinte cheie</button>
                     </div>
                  </form>
               </div>

               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Adauga un articol</h3>
                  <form onSubmit={add_article}>
                     
                     <div className={styles_static.row}>
                        <p>Url articol:</p>
                        <input type="text" name="type" defaultValue={addBlogUrl} onChange={(e) => handleChangeAdd(e, "blogUrl")} required/>
                     </div>

                     <div className={styles_static.row}>
                        <p>Articol data:</p>
                        <input type="date" name="type" defaultValue={addBlogDate} onChange={(e) => handleChangeAdd(e, "blogDate")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Articol imagine:</p>
                        <input type="text" name="type" defaultValue={addBlogImage} onChange={(e) => handleChangeAdd(e, "blogImage")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Articol video:</p>
                        <input type="text" name="type" defaultValue={addBlogVideo} onChange={(e) => handleChangeAdd(e, "blogVideo")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Titlu articol:</p>
                        <input type="text" name="type" defaultValue={addBlogTitle} onChange={(e) => handleChangeAdd(e, "blogTitle")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Subtitlu articol:</p>
                        <input type="text" name="type" defaultValue={addBlogSubTitle} onChange={(e) => handleChangeAdd(e, "blogSubTitle")} />
                     </div>

                     <div className={styles.rowInputSpecial}>
                        <p>Continut articol:</p>
                        <Editor
                           apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                           init={{
                              plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                              mewnubar: "insert",
                              toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                              nonbreaking_force_tab: true
                           }}
                           value={addBlogContent}
                           onEditorChange={(content) => handleChangeAdd(undefined, "blogContent", content)}
                        />
                     </div>

                     <hr />

                     <div className={styles_static.row}>
                        <p>Prioritate:</p>
                        <input type="number" min="0" max="9999999" name="name" defaultValue={addBlogPriority} onChange={(e) => handleChangeAdd(e, "blogPriority")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie articol:</p>
                        <input type="text" name="name" defaultValue={addBlogKeyWord} onChange={(e) => handleChangeAdd(e, "blogKeyWord")} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga articol</button>
                     </div>
                  </form>
               </div>

               <div className={styles.cards}>
                  { 
                     data && Object.keys(data).length && data.sort((a, b) => b.id - a.id).map((object, key) => {
                        blogDate[object["uuid"]] = moment(new Date(object["blog_date"])).format('YYYY-MM-DD');
                        blogImage[object["uuid"]] = object["blog_image"];
                        blogVideo[object["uuid"]] = object["blog_video"];
                        blogTitle[object["uuid"]] = object["blog_title"];
                        blogSubTitle[object["uuid"]] = object["blog_subtitle"];
                        blogContent[object["uuid"]] = object["blog_content"];
                        blogSubContent[object["uuid"]] = object["blog_subcontent"];
                        blogUrl[object["uuid"]] = object["blog_url"];
                        blogPriority[object["uuid"]] = object["priority"];
                        blogKeyWord[object["uuid"]] = object["blog_key_word"];

                        return (
                           <div key={key} className={styles.card} >
                              <div className={styles.row}>
                                 <p>UUID:</p>
                                 <input value={object["uuid"]} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Creat de:</p>
                                 <input value={object["added_by"]} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Modificat de:</p>
                                 <input value={object["modified_by"]} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Creat pe data:</p>
                                 <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Modificat pe data:</p>
                                 <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                              </div>
                              <div className={styles.row + " " + styles.rowInputSpecial}>
                                 <p>Url articol:</p>
                                 <input type="text" defaultValue={blogUrl[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogUrl")} />
                              </div>
                              <div className={styles.row}>
                                 <p>Articol data:</p>
                                 <input type="date" defaultValue={ moment(new Date(blogDate[object["uuid"]])).format('YYYY-MM-DD')} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogDate")}/>
                              </div>
                              <div className={styles.row}>
                                 <p>Articol imagine:</p>
                                 <input type="text" defaultValue={blogImage[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogImage")} />
                              </div>
                              <div className={styles.row}>
                                 <p>Articol Video:</p>
                                 <input type="text" defaultValue={blogVideo[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogVideo")} />
                              </div>
                              <div className={styles.row}>
                                 <p>Titlu articol</p>
                                 <input type="text" defaultValue={blogTitle[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogTitle")} />
                              </div>
                              <div className={styles.row}>
                                 <p>Subtitlu articol</p>
                                 <input type="text" defaultValue={blogSubTitle[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogSubTitle")} />
                              </div>
                              <Collapsible trigger="CLICK AICI pentru a vedea/ascunde detaliile!" className={styles.collapsible}>
                                 
                                 <div className={styles.rowInputSpecial}>
                                    <p>Continut articol</p>
                                    <br />
                                    <Editor
                                       apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                                       init={{
                                          plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                          mewnubar: "insert",
                                          toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                          nonbreaking_force_tab: true
                                       }}
                                       initialValue={blogContent[object["uuid"]]}
                                       onEditorChange={(content) => handleChangeStatic(undefined, object["uuid"], "blogContent", content)}
                                    />
                                 </div>

                                 <hr />

                                 <div className={styles.row}>
                                    <p>Cuvinte cheie articol:</p>
                                    <input type="text" defaultValue={blogKeyWord[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogKeyWord")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Prioritate:</p>
                                    <input type="number" min="0" max="9999999" defaultValue={blogPriority[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "blogPriority")} />
                                 </div>
                              </ Collapsible>
                              <br />
                              <div className={styles.row + " " + styles.rowButtonSpecial}>
                                 <button onClick={update_article} value={object["uuid"]}>Modifica articol</button>
                                 <button onClick={visible_article} value={object["uuid"]}>Vizibilitate</button>
                                 <button onClick={delete_article} value={object["uuid"]}>Sterge articol</button>
                              </div>
                           </div>
                        );
                     })
                  }
               </div>

            </div>

         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Blog;