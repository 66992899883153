import React, { useEffect, useState, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Testimonies.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { FaHouse } from "react-icons/fa6";

import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const TestimoniesUsers = (props) => {

   const [data, setData] = useState(null);
   const [dataContentPage, setDataContentPage] = useState(null);
   const[isLoading, setIsLoading] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/testimonies/temporal');
            const jsonData = await response.json();

            setData(jsonData);

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const add_testimonies_temporal = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      const response = await fetch(BACKEND_URL + '/testimonies/temporal', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
         }),
         credentials: 'include',
      });
      if (response.ok){
         window.location.reload(false);
      }
      else{
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   };

   const delete_testimonies_temporal = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa stergi link testimonial?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/testimonies/temporal?uuid=' + e.target.value, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok){
            window.location.reload(false);
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const copy_links = async (e, path) =>{
      e.preventDefault();

      navigator.clipboard.writeText("https://www.dragosbuchi.ro/scrie-un-comentariu/" + path);
      notify_success("Link copiat cu succes!");
   }

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Testimoniale Utilizatori</h1>
               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Creeaza un un link pentru testimonial</h3>
                  <form onSubmit={add_testimonies_temporal}>
                     <div className={styles_static.row}>
                        <button type='submit'>Creeaza link</button>
                     </div>
                  </form>
               </div>

               <div className={styles.cards}>
                  {
                     data && Array.isArray(data) && data.map((object, key) => {
                        return (
                           <div key={key} className={styles.card} >
                              <div className={styles.row}>
                                 <p>UUID:</p>
                                 <input value={object["uuid"]} disabled/>
                              </div>
                              <div className={styles.row}>
                                 <p>Creat de:</p>
                                 <input value={object["added_by"]} disabled/>
                              </div>
                              <div className={styles.row}>
                                 <p>Creat pe data:</p>
                                 <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled/>
                              </div>
                              <div className={styles.row}>
                                 <p>Utilizat?</p>
                                 <input type="text" defaultValue={object["is_use"] === 0 ? "NU" : "DA"} disabled/>
                              </div>

                              <br />
                              <div className={styles.row + " " + styles.rowButtonSpecial}>
                                 <button onClick={(e) => copy_links(e, object["uuid"])} value={object["uuid"]}>Copiaza link</button>
                                 <button onClick={delete_testimonies_temporal} value={object["uuid"]}>Sterge link</button>
                              </div>
                           </div>
                        );
                     })
                  }
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default TestimoniesUsers;