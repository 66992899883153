import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/AboutMe.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'
import { FaHouse } from "react-icons/fa6";

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const PageVideograf = (props) => {

   const [isLoading, setIsLoading] = useState(false);

   const [data, setData] = useState(null);

   const [pageContent, setPageContent] = useState("");
   const [keyWords, setKeyWords] = useState("");

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);


      const loadUtilsCheckAuth = async () => {
         if (await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setData(jsonData);

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "content_videograf-nunta" && (
                  setPageContent(obiect["value_cp"])
               )
            ))
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "key_words_videograf-nunta" && (
                  setKeyWords(obiect["value_cp"])
               ) 
            ))

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);


   const change_page = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "content_videograf-nunta" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: pageContent,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Valoare modificata cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };
   const change_key_words = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "key_words_videograf-nunta" && (
            uuid = obiect["uuid"]
         ) 
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: keyWords,
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Modificat cu succes!");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangePage = (content) => {
      setPageContent(content);
   };
   const handleChangeKeyWords = (e, uuid) => {
      const { value } = e.target;
      setKeyWords(value);
   };


   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }

         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Videograf Nunta Pagina</h1>
               <hr />
      
               <div className={styles_static.zone_add}>
                  <h3>Modifica pagina</h3>
                  <form onSubmit={change_page}>
                     <div className={styles_static.rowSpecial}>
                        <p>Pagina :</p>
                        <Editor
                           apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                           init={{
                              plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                              mewnubar: "insert",
                              toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                              nonbreaking_force_tab: true
                           }}
                           value={pageContent}
                           onEditorChange={(content) => handleChangePage(content)}
                        />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica pagina</button>
                     </div>
                  </form>
               </div>
               
               <div className={styles_static.zone_add}>
                  <h3>Modifica cuvinte cheie pagina principala</h3>
                  <form onSubmit={change_key_words}>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie:</p>
                        <input type="text" name="url" defaultValue={keyWords} onChange={(e) => handleChangeKeyWords(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica cuvinte cheie</button>
                     </div>
                  </form>
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default PageVideograf;