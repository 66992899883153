export const getCookieValue = (name) => {
   const cookies = document.cookie.split(';');
   for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${name}=`)) {
         return cookie.substring(name.length + 1);
      }
   }
   return null;
};

export const deleteCookie = (name) => {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};