import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { FaHouse } from "react-icons/fa6";

import styles_static from '../styles/Static.module.css';
import styles from '../styles/AccountSettings.module.css';
import 'react-toastify/dist/ReactToastify.css';
import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';


const AccountSettings = (props) => {


   const [data, setData] = useState(null);
   const[isLoading, setIsLoading] = useState(false);

   const [emailAdd, setEmailAdd] = useState("");
   const [usernameAdd, setUsernameAdd] = useState("");
   const [passwordAdd, setPasswordAdd] = useState("");

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);
      
      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/me', {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
            const jsonData = await response.json();

            setEmailAdd(jsonData.email);
            setUsernameAdd(jsonData.username);
            console.log(jsonData);
            setData(jsonData);
         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const change_email = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      var answer = window.confirm("Esti sigur ca vrei sa modifici e-mailul?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/users?uuid=' + data["uuid"], {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               email: emailAdd,
            }),
            credentials: 'include',
         });
         if (response.ok){
            window.alert("Totul este ok, te rog sa te autentifici!");
            window.location.reload(false);
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const change_username = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      var answer = window.confirm("Esti sigur ca vrei sa modifici username ul?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/users?uuid=' + data["uuid"], {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               username: usernameAdd,
            }),
            credentials: 'include',
         });
         if (response.ok){
            window.alert("Totul este ok, te rog sa te autentifici!");
            window.location.reload(false);
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const change_password = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      var answer = window.confirm("Esti sigur ca vrei sa modifici parola?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/users?uuid=' + data["uuid"], {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               password: passwordAdd,
               email: data["email"],
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Parola a fost schimbata! Vei primi pe e-mail confirmarea");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };


   const handleChangeEmail = (e, uuid) => {
      const { value } = e.target;
      setEmailAdd(value);
   };

   const handleChangeUsername = (e, uuid) => {
      const { value } = e.target;
      setUsernameAdd(value);
   };

   const handleChangePassword = (e, uuid) => {
      const { value } = e.target;
      setPasswordAdd(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
      
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Setari cont</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica e-mail</h3>
                  <form onSubmit={change_email}>
                     <div className={styles_static.row}>
                        <p>E-mail:</p>
                        <input type="email" defaultValue={emailAdd} onChange={(e) => handleChangeEmail(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica e-mail</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica username</h3>
                  <form onSubmit={change_username}>
                     <div className={styles_static.row}>
                        <p>Username:</p>
                        <input type="username" defaultValue={usernameAdd} onChange={(e) => handleChangeUsername(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica username</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica parola</h3>
                  <form onSubmit={change_password}>
                     <div className={styles_static.row}>
                        <p>Parola noua:</p>
                        <input type="password"  defaultValue={passwordAdd} onChange={(e) => handleChangePassword(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica parola</button>
                     </div>
                  </form>
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default AccountSettings;