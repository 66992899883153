import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Footer.module.css';
import 'react-toastify/dist/ReactToastify.css';
import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'
import { FaHouse, FaSquareVimeo  } from "react-icons/fa6";

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Footer = (props) => {

   const[isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState(null);
   
   const [footerLocation, setFooterLocation] = useState("");
   const [footerEmail, setFooterEmail] = useState("");
   const [footerPhone, setFooterPhone] = useState("");
   const [footerInstagram, setFooterInstagram] = useState("");
   const [footerFacebook, setFooterFacebook] = useState("");
   const [footerVimeo, setFooterVimeo] = useState("");

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();
      
      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setData(jsonData);
            
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "footer_location" ? (
                  setFooterLocation(obiect["value_cp"])
               ) : obiect["key_cp"] === "footer_email" ? (
                  setFooterEmail(obiect["value_cp"])
               ) : obiect["key_cp"] === "footer_phone" ? (
                  setFooterPhone(obiect["value_cp"])
               ) : obiect["key_cp"] === "footer_instagram" ? (
                  setFooterInstagram(obiect["value_cp"])
               ) : obiect["key_cp"] === "footer_facebook" ? (
                  setFooterFacebook(obiect["value_cp"])
               ) : obiect["key_cp"] === "footer_vimeo" && (
                  setFooterVimeo(obiect["value_cp"])
               )
            ))
         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const update_footer = async (e, type) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "", value;
      
      if(type === "email"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "footer_email" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = footerEmail;
      }
      else  if(type === "locatie"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "footer_location" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = footerLocation;
      }
      else  if(type === "telefon"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "footer_phone" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = footerPhone;
      }
      else  if(type === "instagram"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "footer_instagram" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = footerInstagram;
      }
      else  if(type === "facebook"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "footer_facebook" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = footerFacebook;
      }
      else  if(type === "vimeo"){
         data && data.map((obiect, index) => (
            obiect["key_cp"] === "footer_vimeo" && (
               uuid = obiect["uuid"]
            ) 
         ))
         value = footerVimeo;
      }

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: value,
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Valoare modificata cu succes!");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChange= (e, type) => {
      if(type === "email"){
         const { value } = e.target;
         setFooterEmail(value);
      }
      else if(type === "locatie"){
         const { value } = e.target;
         setFooterLocation(value);
      }
      else if(type === "telefon"){
         const { value } = e.target;
         setFooterPhone(value);
      }
      else if(type === "instagram"){
         const { value } = e.target;
         setFooterInstagram(value);
      }
      else if(type === "facebook"){
         const { value } = e.target;
         setFooterFacebook(value);
      }
      else if(type === "vimeo"){
         const { value } = e.target;
         setFooterVimeo(value);
      }
      
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Footer</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica Footer Email</h3>
                  <form onSubmit={(e) => update_footer(e, "email")}>
                     <div className={styles_static.row}>
                        <p>Email:</p>
                        <input type="text" defaultValue={footerEmail} onChange={(e) => handleChange(e, "email")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica email</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Footer Locatie</h3>
                  <form onSubmit={(e) => update_footer(e, "locatie")}>
                     <div className={styles_static.row}>
                        <p>Locatie:</p>
                        <input type="text"  defaultValue={footerLocation} onChange={(e) => handleChange(e, "locatie")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica locatie</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Footer Telefon</h3>
                  <form onSubmit={(e) => update_footer(e, "telefon")}>
                     <div className={styles_static.row}>
                        <p>Telefon:</p>
                        <input type="text" defaultValue={footerPhone} onChange={(e) => handleChange(e, "telefon")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica telefon</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Footer Instragram</h3>
                  <form onSubmit={(e) => update_footer(e, "instagram")}>
                     <div className={styles_static.row}>
                        <p>Instagram:</p>
                        <input type="text" defaultValue={footerInstagram} onChange={(e) => handleChange(e, "instagram")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica Instagram</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Footer Facebook</h3>
                  <form onSubmit={(e) => update_footer(e, "facebook")}>
                     <div className={styles_static.row}>
                        <p>Facebook:</p>
                        <input type="text" defaultValue={footerFacebook} onChange={(e) => handleChange(e, "facebook")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica Facebook</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Modifica Footer Vimeo</h3>
                  <form onSubmit={(e) => update_footer(e, "vimeo")}>
                     <div className={styles_static.row}>
                        <p>Vimeo:</p>
                        <input type="text" defaultValue={footerVimeo} onChange={(e) => handleChange(e, "vimeo")}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica Vimeo</button>
                     </div>
                  </form>
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Footer;