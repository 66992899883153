import React, { useEffect, useState, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import Collapsible from 'react-collapsible';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Testimonies.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { FaHouse } from "react-icons/fa6";

import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Testimonies = (props) => {

   const [data, setData] = useState(null);
   const [dataContentPage, setDataContentPage] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const [commentType, setCommentType] = useState({});
   const [commentUserFoto, setCommentUserFoto] = useState({});
   const [commentName, setCommentName] = useState({});
   const [commentTitle, setCommentTitle] = useState({});
   const [commentDescription, setCommentDescription] = useState({});
   const [commentRedirect, setCommentRedirect] = useState({});
   const [commentTypeBox, setCommentTypeBox] = useState({});
   const [commentColor, setCommentColor] = useState({});
   const [commentVisible, setCommentVisible] = useState({});
   const [commentPriority, setCommentPriority] = useState({});

   const [addCommentType, setAddCommentType] = useState("");
   const [addCommentUserFoto, setAddCommentUserFoto] = useState("");
   const [addCommentName, setAddCommentName] = useState("");
   const [addCommentTitle, setAddCommentTitle] = useState("");
   const [addCommentDescription, setAddCommentDescription] = useState("");
   const [addCommentRedirect, setAddCommentRedirect] = useState("");
   const [addCommentTypeBox, setAddCommentTypeBox] = useState(1);
   const [addCommentColor, setAddCommentColor] = useState("#DCDCDC");
   const [addCommentPriority, setAddCommentPriority] = useState(0);
   const [keyWords, setKeyWords] = useState(undefined);

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if (await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchDataContentPage = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setDataContentPage(jsonData);

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "key_words_testimonies" && (
                  setKeyWords(obiect["value_cp"])
               )
            ))

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchDataContentPage();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/testimonies');
            const jsonData = await response.json();

            setData(jsonData);

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const add_testimonies = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      const response = await fetch(BACKEND_URL + '/testimonies?', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            comment_type: addCommentType,
            comment_user_foto: addCommentUserFoto,
            comment_name: addCommentName,
            comment_title: addCommentTitle,
            comment_description: addCommentDescription,
            comment_redirect: addCommentRedirect,
            comment_typeBox: addCommentTypeBox,
            comment_color: addCommentColor,
            priority:addCommentPriority,
         }),
         credentials: 'include',
      });
      if (response.ok) {
         window.location.reload(false);
      }
      else {
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   };

   const delete_testimonies = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa stergi testimonialul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/testimonies?uuid=' + e.target.value, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            window.location.reload(false);
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const update_visible_testimonies = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa modifici vizibilitatea testimonialul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/testimonies/visible?uuid=' + e.target.value, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            window.location.reload(false);
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const update_testimonies = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa modifici testimonial?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/testimonies?uuid=' + e.target.value, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               comment_type: commentType[e.target.value],
               comment_user_foto: commentUserFoto[e.target.value],
               comment_name: commentName[e.target.value],
               comment_title: commentTitle[e.target.value],
               comment_description: commentDescription[e.target.value],
               comment_redirect: commentRedirect[e.target.value],
               comment_typeBox: commentTypeBox[e.target.value],
               comment_color: commentColor[e.target.value],
               priority:commentPriority[e.target.value],
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Comentariu modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeAdd = (e, type, content) => {
      if (type === "TypeAdd") {
         const { value } = e.target;
         setAddCommentType(value);
      }
      else if (type === "FotoAdd") {
         const { value } = e.target;
         setAddCommentUserFoto(value);
      }
      else if (type === "NameAdd") {
         const { value } = e.target;
         setAddCommentName(value);
      }
      else if (type === "TitleAdd") {
         const { value } = e.target;
         setAddCommentTitle(value);
      }
      else if (type === "DescriptionAdd") {
         setAddCommentDescription(content)
      }
      else if (type === "RedirectAdd") {
         const { value } = e.target;
         setAddCommentRedirect(value);
      }
      else if (type === "TypeBoxAdd") {
         const { value } = e.target;
         setAddCommentTypeBox(value);
      }
      else if (type === "PriorityAdd") {
         const { value } = e.target;
         setAddCommentPriority(value);
      }
      else if (type === "ColorAdd") {
         const { value } = e.target;
         setAddCommentColor(value);
      }
   };

   const handleChangeStatic = (e, uuid, type, content) => {
      if (type === "TypeAdd") {
         const { value } = e.target;
         commentType[uuid] = value;
      }
      else if (type === "FotoAdd") {
         const { value } = e.target;
         commentUserFoto[uuid] = value;
      }
      else if (type === "NameAdd") {
         const { value } = e.target;
         commentName[uuid] = value;
      }
      else if (type === "TitleAdd") {
         const { value } = e.target;
         commentTitle[uuid] = value;
      }
      else if (type === "DescriptionAdd") {
         commentDescription[uuid] = content;
      }
      else if (type === "RedirectAdd") {
         const { value } = e.target;
         commentRedirect[uuid] = value;
      }
      else if (type === "TypeBoxAdd") {
         const { value } = e.target;
         commentTypeBox[uuid] = value;
      }
      else if (type === "PriorityAdd") {
         const { value } = e.target;
         commentPriority[uuid] = value;
      }
      else if (type === "ColorAdd") {
         const { value } = e.target;
         commentColor[uuid] = value;
      }
   };

   const change_key_words = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      dataContentPage && dataContentPage.map((obiect, index) => (
         obiect["key_cp"] === "key_words_testimonies" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: keyWords,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeKeyWords = (e, uuid) => {
      const { value } = e.target;
      setKeyWords(value);
   };


   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Testimoniale</h1>
               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Modifica cuvinte cheie testimoniale</h3>
                  <form onSubmit={change_key_words}>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie:</p>
                        <input type="text" name="url" defaultValue={keyWords} onChange={(e) => handleChangeKeyWords(e)} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica cuvinte cheie</button>
                     </div>
                  </form>
               </div>

               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Adauga un comentariu</h3>
                  <form onSubmit={add_testimonies}>
                     <div className={styles_static.row}>
                        <p>Nume persoana:</p>
                        <input type="text" name="name" defaultValue={addCommentName} onChange={(e) => handleChangeAdd(e, "NameAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Tip persoana:</p>
                        <input type="text" name="type" defaultValue={addCommentType} onChange={(e) => handleChangeAdd(e, "TypeAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Url foto persoana:</p>
                        <input type="text" name="url" defaultValue={addCommentUserFoto} onChange={(e) => handleChangeAdd(e, "FotoAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Titlu:</p>
                        <input type="text" name="title" defaultValue={addCommentTitle} onChange={(e) => handleChangeAdd(e, "TitleAdd")} />
                     </div>
                     <div className={styles.rowInputSpecial}>
                        <p>Descriere:</p>
                        <br />
                        <Editor
                           apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                           init={{
                              plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                              mewnubar: "insert",
                              toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                              nonbreaking_force_tab: true
                           }}
                           value={addCommentDescription}
                           onEditorChange={(content) => handleChangeAdd(undefined, "DescriptionAdd", content)}
                        />
                     </div>
                     <div className={styles_static.row}>
                        <p>Redirectionare:</p>
                        <input type="text" name="redirect" defaultValue={addCommentRedirect} onChange={(e) => handleChangeAdd(e, "RedirectAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Culoare:</p>
                        <input type="color" defaultValue={addCommentColor} onChange={(e) => handleChangeAdd(e, "ColorAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Prioritate:</p>
                        <input type="number" min="0" max="9999999" name="name" defaultValue={addCommentPriority} onChange={(e) => handleChangeAdd(e, "PriorityAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <div>
                           <div className={styles.divFormSpecial}>
                              <div className={styles.divFormSpecialChild}>
                                 <label htmlFor="html">Tip 1</label>
                                 <input type="radio" name="radio" value="1" onChange={(e) => handleChangeAdd(e, "TypeBoxAdd")} defaultChecked />
                              </div>

                              <div className={styles.divFormSpecialChild}>
                                 <label htmlFor="html">Tip 2</label>
                                 <input type="radio" name="radio" value="2" onChange={(e) => handleChangeAdd(e, "TypeBoxAdd")} />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga comentariu</button>
                     </div>
                  </form>
               </div>

               <div className={styles.cards}>
                  {
                     data && data.sort((a, b) => b.id - a.id).map((object, key) => {
                        commentType[object["uuid"]] = object["comment_type"];
                        commentUserFoto[object["uuid"]] = object["comment_user_foto"];
                        commentName[object["uuid"]] = object["comment_name"];
                        commentTitle[object["uuid"]] = object["comment_title"];
                        commentDescription[object["uuid"]] = object["comment_description"];
                        commentRedirect[object["uuid"]] = object["comment_redirect"];
                        commentTypeBox[object["uuid"]] = object["comment_typeBox"];
                        commentColor[object["uuid"]] = object["comment_color"];
                        commentVisible[object["uuid"]] = object["is_visible"];
                        commentPriority[object["uuid"]] = object["priority"];


                        return (
                           <>
                              <div key={key} className={styles.card} >
                                 <div className={styles.row}>
                                    <p>UUID:</p>
                                    <input value={object["uuid"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat de:</p>
                                    <input value={object["added_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat de:</p>
                                    <input value={object["modified_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat pe data:</p>
                                    <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat pe data:</p>
                                    <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Este vizibil?</p>
                                    <input type="text" defaultValue={commentVisible[object["uuid"]] === 0 ? "NU" : "DA"} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Nume persoana:</p>
                                    <input type="text" defaultValue={commentName[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "NameAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Tip Persoana:</p>
                                    <input type="text" defaultValue={commentType[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Url foto persoana:</p>
                                    <input type="text" defaultValue={commentUserFoto[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "FotoAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Titlu:</p>
                                    <input type="text" defaultValue={commentTitle[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "TitleAdd")} />
                                 </div>
                                 <Collapsible trigger="CLICK AICI pentru a vedea/ascunde detaliile!" className={styles.collapsible}>
                                    <div className={styles.rowInputSpecial}>
                                       <p>Descriere:</p>
                                       <br />
                                       <Editor
                                          apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                                          init={{
                                             plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                             mewnubar: "insert",
                                             toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                             nonbreaking_force_tab: true
                                          }}
                                          initialValue={commentDescription[object["uuid"]]}
                                          onEditorChange={(content) => handleChangeStatic(undefined, object["uuid"], "DescriptionAdd", content)}
                                       />
                                    </div>
                                    <div className={styles.row}>
                                       <p>Redirectionare:</p>
                                       <input type="text" defaultValue={commentRedirect[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "RedirectAdd")} />
                                    </div>
                                    <div className={styles.row}>
                                       <p>Culoare:</p>
                                       <input type="color" defaultValue={commentColor[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "ColorAdd")} />
                                    </div>
                                    <div className={styles.row}>
                                       <p>Prioritate:</p>
                                       <input type="number" min="0" max="9999999" defaultValue={commentPriority[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "PriorityAdd")} />
                                    </div>
                                    <div className={styles.row + " " + styles.rowSpecial}>
                                       <p>Tip:</p>
                                       <div>
                                          <div className={styles.divFormSpecial}>
                                             <div className={styles.divFormSpecialChild}>
                                                <label htmlFor="html">Tip 1</label>
                                                <input type="radio" name={"radio " + key} value="1" defaultChecked={commentTypeBox[object["uuid"]] === 1} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeBoxAdd")} />
                                             </div>

                                             <div className={styles.divFormSpecialChild}>
                                                <label htmlFor="html">Tip 2</label>
                                                <input type="radio" name={"radio " + key} value="2" defaultChecked={commentTypeBox[object["uuid"]] === 2} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeBoxAdd")} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </Collapsible>

                                 <div className={styles.row + " " + styles.rowButtonSpecial}>
                                    <button onClick={update_testimonies} value={object["uuid"]}>Modifica comentariu</button>
                                    <button onClick={update_visible_testimonies} value={object["uuid"]}>Modifica vizibilitate</button>
                                    <button onClick={delete_testimonies} value={object["uuid"]}>Sterge comentariu</button>
                                 </div>
                              </div>
                              <hr />
                           </>
                        );
                     })
                  }
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Testimonies;