import React, { useEffect, useState } from 'react';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import {FaAlignJustify, FaUsers, FaRegFolder, FaNewspaper, FaHouseChimney, FaQrcode, FaFlorinSign, FaPerson, FaRegComments, FaRegAddressBook, FaIdCardClip, FaPenToSquare, FaArrowRightFromBracket} from "react-icons/fa6";

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Dashboard.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { Cookies } from 'react-cookie';
import {getCookieValue, deleteCookie} from '../utils/Cookie';

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Dashboard = (props) => {

   const [data, setData] = useState(null);
   const[isLoading, setIsLoading] = useState(false);
   
   const navigate = useNavigate();
   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();
      
      const checkToken = async () => {
         const response = await fetch(BACKEND_URL + '/me', {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (!response.ok) {
            navigate("/");
         }
         else{
            const jsonData = await response.json();
            setData(jsonData);
         }
      };
      checkToken();
      
      setIsLoading(false);
      
   }, []);

   const handleClickLeave = async () => {
      setIsLoading(true);

      await fetch(BACKEND_URL + '/logout', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         credentials: 'include',
      });

      deleteCookie('auth_token');

      setIsLoading(false);
      navigate("/");
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <div className={styles.row}>
                  <h1>Salut, {data && data.username} !</h1>

                  <br />

                  <div className={styles.logout}>
                     <a href="/" onClick={handleClickLeave}><p className={"forHidden"}>Deconectare</p> <FaArrowRightFromBracket /></a>
                  </div>

                  <br />
               </div>
               <div className={styles.container}>
                  <a href = "/dashboard" title="Dashboard" className={styles.card} >
                     <FaQrcode /> 
                     <h3 className="forHidden">Dashboard</h3>
                  </a>
                  <a href = "/users" title="Utilizatori" className={styles.card} >
                     <FaUsers /> 
                     <h3 className="forHidden">Utilizatori</h3>
                  </a>
                  <a href = "/home" title="Home" className={styles.card} >
                     <FaHouseChimney /> 
                     <h3 className="forHidden">Home</h3>
                  </a>
                  <a href = "/pagevideograf" title="Videograf Nunta Pagina" className={styles.card} >
                     <h3 className="forHidden">Videograf Nunta Pagina</h3>
                  </a>
                  <a href = "/about-me" title="Despre mine" className={styles.card} >
                     <FaPerson /> 
                     <h3 className="forHidden">Despre mine</h3>
                  </a>
                  <a href = "/testimonies" title="Testimoniale" className={styles.card} >
                     <FaRegComments /> 
                     <h3 className="forHidden">Testimoniale</h3>
                  </a>
                  <a href = "/testimonies-users" title="Testimoniale Utilizatori" className={styles.card} >
                     <FaRegComments /> 
                     <h3 className="forHidden">Testimoniale Utilizatori</h3>
                  </a>
                  <a href = "/offer" title="Pachete" className={styles.card} >
                     <FaRegAddressBook /> 
                     <h3 className="forHidden">Pachete</h3>
                  </a>
                  <a href = "/price_page" title="Pachete Pret" className={styles.card} >
                     <FaRegAddressBook /> 
                     <h3 className="forHidden">Pachete Pret</h3>
                  </a>
                  <a href = "/askoffer" title="Cere Oferta" className={styles.card} >
                     <FaRegAddressBook /> 
                     <h3 className="forHidden">Cere Oferta</h3>
                  </a>
                  <a href = "/portofolio" title="Portofolio" className={styles.card} >
                     <FaNewspaper /> 
                     <h3 className="forHidden">Portofoliu</h3>
                  </a>
                  <a href = "/blog" title="Blog" className={styles.card} >
                     <FaRegAddressBook /> 
                     <h3 className="forHidden">Blog</h3>
                  </a>
                  <a href = "/contact" title="Contact" className={styles.card} >
                     <FaIdCardClip /> 
                     <h3 className="forHidden">Contact</h3>
                  </a>
                  <a href = "/footer" title="Footer" className={styles.card} >
                     <FaFlorinSign /> 
                     <h3 className="forHidden">Footer</h3>
                  </a>
                  <a href = "https://www.filemanager.dragosbuchi.ro/" target="_blank" title="FileManager" className={styles.card} >
                     <FaRegFolder /> 
                     <h3 className="forHidden">FileManager</h3>
                  </a>
                  <a href = "/account-settings" title="Setari cont" className={styles.card} >
                     <FaPenToSquare /> 
                     <h3 className="forHidden">Setari cont</h3>
                  </a>
               </div>
            </div>
         </main>
      </>
   );
};

export default Dashboard;