import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/AboutMe.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'
import { FaHouse } from "react-icons/fa6";

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const AboutMe = (props) => {

   const [isLoading, setIsLoading] = useState(false);

   const [data, setData] = useState(null);

   const [aboutMeUrl, setAboutMeUrl] = useState("");
   const [aboutMeParagraph, setAboutMeParagraph] = useState("");
   const [aboutMeParagraphImage, setAboutMeParagraphImage] = useState("");

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);


      const loadUtilsCheckAuth = async () => {
         if (await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setData(jsonData);

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "about-me_foto" && (
                  setAboutMeUrl(obiect["value_cp"])
               )
            ))
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "about-me_paragraph" && (
                  setAboutMeParagraph(obiect["value_cp"])
               )
            ))
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "about-me_foto2" && (
                  setAboutMeParagraphImage(obiect["value_cp"])
               )
            ))
         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const change_url_aboutMe = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "about-me_foto" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: aboutMeUrl,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            window.location.reload(false);
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const change_paragraph1_aboutMe = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "about-me_paragraph" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: aboutMeParagraph,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Valoare modificata cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const change_paragraphImage_aboutMe = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "about-me_foto2" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: aboutMeParagraphImage,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Valoare modificata cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeUrl = (e) => {
      const { value } = e.target;
      setAboutMeUrl(value);
   };

   const handleChangeParagraph = (content) => {
      setAboutMeParagraph(content);
   };

   const handleChangeParagraphImage = (e) => {
      const { value } = e.target;
      setAboutMeParagraphImage(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }

         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Despre Mine</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica imagine - despre mine</h3>
                  <form onSubmit={change_url_aboutMe}>
                     <div className={styles_static.row}>
                        <p>Url imagine:</p>
                        <input type="text" name="url" defaultValue={aboutMeUrl} onChange={(e) => handleChangeUrl(e)} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica url</button>
                     </div>
                     <div className={styles_static.row}>
                        {
                           data && data.map((obiect, index) => (
                              obiect["key_cp"] === "about-me_foto" && obiect["value_cp"] !== "" && (
                                 <img key={index} src={obiect["value_cp"]} className={styles.imagesp} alt="" />
                              )
                           ))
                        }
                     </div>
                  </form>
               </div>

               <div className={styles_static.zone_add}>
                  <h3>Modifica paragraf - despre mine</h3>
                  <form onSubmit={change_paragraph1_aboutMe}>
                     <div className={styles_static.rowSpecial}>
                        <p>Paragraf :</p>
                        <Editor
                           apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                           init={{
                              plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                              mewnubar: "insert",
                              toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                              nonbreaking_force_tab: true
                           }}
                           value={aboutMeParagraph}
                           onEditorChange={(content) => handleChangeParagraph(content)}
                        />
                     </div>
                     <div>
                        
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica paragraf 1</button>
                     </div>
                  </form>
               </div>

               <div className={styles_static.zone_add}>
                  <h3>Modifica imagine paragraf</h3>
                  <form onSubmit={change_paragraphImage_aboutMe}>
                     <div className={styles_static.row}>
                        <p>Url Imagine:</p>
                        <input type="text" name="paragraphImage" defaultValue={aboutMeParagraphImage} onChange={(e) => handleChangeParagraphImage(e)} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica imagine paragraf</button>
                     </div>
                  </form>
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default AboutMe;