import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import styles_static from '../styles/Static.module.css';
import styles from '../styles/Offer.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { FaHouse } from "react-icons/fa6";

import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Offer = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [keyCp, setKeyCp] = useState({});
    const [valueCp, setValueCp] = useState({});

    const [paragraph, setParagraph] = useState("");

    const [addpackage1, setAddpackage1] = useState("");

    const [addpackage2, setAddpackage2] = useState("");

    const [addpackage3, setAddpackage3] = useState("");

    const [addpackage4, setAddpackage4] = useState("");

    const [addUrlImage, setAddUrlImage] = useState("");
    const [addUrlImageID, setAddUrlImageId] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);

        const loadUtilsCheckAuth = async () => {
            if (await checkAuth() === false)
                navigate('/');
        }
        loadUtilsCheckAuth();

        const fetchData = async () => {
            try {
                const response = await fetch(BACKEND_URL + '/content_page');
                const jsonData = await response.json();

                jsonData && jsonData.map((obiect, index) => (
                    obiect["key_cp"] === "pricePage_image" && (
                        setAddUrlImage(obiect["value_cp"]),
                        setAddUrlImageId(obiect["uuid"])
                    ),
                    obiect["key_cp"] === "package1_price" && (
                        setAddpackage1(obiect["value_cp"])
                    ),
                    obiect["key_cp"] === "package2_price" && (
                        setAddpackage2(obiect["value_cp"])
                    ),
                    obiect["key_cp"] === "package3_price" && (
                        setAddpackage3(obiect["value_cp"])
                    ),
                    obiect["key_cp"] === "package4_price" && (
                        setAddpackage4(obiect["value_cp"])
                    ),
                    obiect["key_cp"] === "pricePage_content" && (
                        setParagraph(obiect["value_cp"])
                    )
                ))

                setData(jsonData);

            } catch (error) {
                console.error('Eroare:', error);
            }
        };
        fetchData();

        setIsLoading(false);
    }, []);


    const update_price = async (e, type) => {
        setIsLoading(true);
        e.preventDefault();

        var uuid = "0";
        var val = "0";

        if (type === 1) {
            uuid = "4ea27ba0-ce70-11ee-a506-0242ac120002"
            val = addpackage1;
        } else if (type === 2) {
            uuid = "59c8130a-ce70-11ee-a506-0242ac120002"
            val = addpackage2;
        } else if (type === 3) {
            uuid = "63965ab8-ce70-11ee-a506-0242ac120002"
            val = addpackage3;
        } else if (type === 4) {
            uuid = "6e2bc896-ce70-11ee-a506-0242ac120002"
            val = addpackage4;
        } else if(type === 9){
            uuid = "e08be3ea-ce6f-11ee-a506-0242ac120002"
            val = paragraph;
        }

        var answer = window.confirm("Esti sigur ca vrei sa modifici valoarea?");
        if (answer) {
            const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    value_cp: val,
                }),
                credentials: 'include',
            });
            if (response.ok) {
                notify_success("Pret modificat cu succes!");
            }
            else {
                notify_error("Ceva nu a mers bine!");
            }
        }

        setIsLoading(false);
    };

    const update_image = async (e, type) => {
        setIsLoading(true);

        e.preventDefault();

        const response = await fetch(BACKEND_URL + '/content_page?uuid=' + addUrlImageID, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                value_cp: addUrlImage
            }),
            credentials: 'include',
        });
        if (response.ok) {
            notify_success("Imagine oferta modificata cu succes!");
        }
        else {
            notify_error("Ceva nu a mers bine!");
        }

        setIsLoading(false);
    };

    const handleAddPackage = (e, type, content) => {
        if (type === "1") {
            const { value } = e.target;
            setAddpackage1(value);
        }
        else if (type === "2") {
            const { value } = e.target;
            setAddpackage2(value);
        }
        else if (type === "3") {
            const { value } = e.target;
            setAddpackage3(value);
        }
        else if (type === "4") {
            const { value } = e.target;
            setAddpackage4(value);
        }
        else if (type === "9") {
            setParagraph(content);
        }
    };

    const handleAddUrlImage = (e) => {
        const { value } = e.target;
        setAddUrlImage(value);
    };

    return (
        <>
            {
                isLoading === true && (
                    <Loading />
                )
            }

            <main className={styles_static.main}>
                <div className={styles_static.content}>
                    <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
                    <h1>Pagina Preturi</h1>
                    <hr />
                    <div className={styles_static.zone_add}>
                        <h3>Pret pachet 1</h3>
                        <form onSubmit={(e) => update_price(e, 1)}>
                            <div className={styles_static.row}>
                                <p>Pret:</p>
                                <input type="text" name="type" defaultValue={addpackage1} onChange={(e) => handleAddPackage(e, "1")} />
                            </div>
                            <div className={styles_static.row}>
                                <button type='submit'>Adauga valoare pachet 1</button>
                            </div>
                        </form>
                    </div>

                    <div className={styles_static.zone_add}>
                        <h3>Pret pachet 2</h3>
                        <form onSubmit={(e) => update_price(e, 2)}>
                            <div className={styles_static.row}>
                                <p>Pret:</p>
                                <input type="text" name="type" defaultValue={addpackage2} onChange={(e) => handleAddPackage(e, "2")} />
                            </div>
                            <div className={styles_static.row}>
                                <button type='submit'>Adauga valoare pachet 2</button>
                            </div>
                        </form>
                    </div>

                    <div className={styles_static.zone_add}>
                        <h3>Pret pachet 3</h3>
                        <form onSubmit={(e) => update_price(e, 3)}>
                            <div className={styles_static.row}>
                                <p>Pret:</p>
                                <input type="text" name="type" defaultValue={addpackage3} onChange={(e) => handleAddPackage(e, "3")} />
                            </div>
                            <div className={styles_static.row}>
                                <button type='submit'>Adauga valoare pachet 3</button>
                            </div>
                        </form>
                    </div>
                    <div className={styles_static.zone_add}>
                        <h3>Pret pachet 4</h3>
                        <form onSubmit={(e) => update_price(e, 4)}>
                            <div className={styles_static.row}>
                                <p>Pret:</p>
                                <input type="text" name="type" defaultValue={addpackage4} onChange={(e) => handleAddPackage(e, "4")} />
                            </div>
                            <div className={styles_static.row}>
                                <button type='submit'>Adauga valoare pachet 4</button>
                            </div>
                        </form>
                    </div>

                    <div className={styles_static.zone_add}>
                        <h3>Url imagine</h3>
                        <form onSubmit={(e) => update_image(e)}>
                            <div className={styles_static.row}>
                                <p>url:</p>
                                <input type="text" name="type" defaultValue={addUrlImage} onChange={(e) => handleAddUrlImage(e)} />
                            </div>
                            <div className={styles_static.row}>
                                <button type='submit'>Adauga url imagine</button>
                            </div>
                        </form>
                    </div>

                    <div className={styles_static.zone_add}>
                        <h3>Modifica continut</h3>
                        <form onSubmit={(e) => update_price(e, 9)}>
                            <div className={styles_static.rowSpecial}>
                                <p>Paragraf :</p>
                                <Editor
                                apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                                init={{
                                    plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                    mewnubar: "insert",
                                    toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    nonbreaking_force_tab: true
                                }}
                                value={paragraph}
                                onEditorChange={(content) => handleAddPackage(null, "9", content)}
                                />
                            </div>
                            <div>
                                
                            </div>
                            <div className={styles_static.row}>
                                <button type='submit'>Modifica continut</button>
                            </div>
                        </form>
                    </div>


                </div>
            </main>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                theme="light"
            />
        </>
    );
};

export default Offer;