import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles_static from '../styles/Static.module.css';

const Loading = (props) => {

   return (
      <>
         <div className={styles_static.loaderContainer}>
            <div className={styles_static.spinner}></div>
         </div>
      </>
   );
};

export default Loading;