import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import Collapsible from 'react-collapsible';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Portofolio.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { FaHouse } from "react-icons/fa6";

import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Portofolio = (props) => {

   const [data, setData] = useState(null);
   const [dataContentPage, setDataContentPage] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const [eventType, setEventType] = useState({});
   const [eventDate, setEventDate] = useState({});
   const [eventLocation, setEventLocation] = useState({});
   const [eventName, setEventName] = useState({});
   const [eventDescription, setEventDescription] = useState({});
   const [eventSubDescription, setEventSubDescription] = useState({});
   const [eventImageVideo1, setEventImageVideo1] = useState({});
   const [eventImageVideo2, setEventImageVideo2] = useState({});
   const [eventImageVideo3, setEventImageVideo3] = useState({});
   const [eventImageVideo4, setEventImageVideo4] = useState({});
   const [eventImageVideo5, setEventImageVideo5] = useState({});
   const [eventUrl, setEventUrl] = useState({});
   const [eventRedirect, setEventRedirect] = useState({});
   const [eventColor, setEventColor] = useState({});
   const [eventKeyWords, setEventKeyWords] = useState({});
   const [eventPriority, setEventPriority] = useState({});
   

   const [addEventType, setAddEventType] = useState("wedding");
   const [addEventDate, setAddEventDate] = useState(new Date());
   const [addEventLocation, setAddEventLocatio] = useState("");
   const [addEventName, setAddEventName] = useState("");
   const [addEventDescription, setAddEventDescription] = useState("");
   const [addEventSubDescription, setAddEventSubDescription] = useState("");
   const [addEventImageVideo1, setAddEventImageVideo1] = useState("");
   const [addEventImageVideo2, setAddEventImageVideo2] = useState("");
   const [addEventImageVideo3, setAddEventImageVideo3] = useState("");
   const [addEventImageVideo4, setAddEventImageVideo4] = useState("");
   const [addEventImageVideo5, setAddEventImageVideo5] = useState("");
   const [addEventUrl, setAddEventUrl] = useState("");
   const [addEventRedirect, setAddEventRedirect] = useState("");
   const [addEventColor, setAddEventColor] = useState("");
   const [addEventKeyWords, setAddEventKeyWords] = useState("");
   const [addEventPriority, setAddEventPriority] = useState(0);

   const [keyWords, setKeyWords] = useState(undefined);

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if (await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchDataContentPage = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setDataContentPage(jsonData);

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "key_words_portofolio" && (
                  setKeyWords(obiect["value_cp"])
               )
            ))

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchDataContentPage();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/portofolio');
            const jsonData = await response.json();
            setData(jsonData);

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const add_event = async (e) => {
      setIsLoading(true);

      e.preventDefault();

      const response = await fetch(BACKEND_URL + '/portofolio?', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            event_type: addEventType,
            event_date: addEventDate,
            event_location: addEventLocation,
            event_name: addEventName,
            event_description: addEventDescription,
            event_subdescription: addEventSubDescription,

            event_image_video_1: addEventImageVideo1,
            event_image_video_2: addEventImageVideo2,
            event_image_video_3: addEventImageVideo3,
            event_image_video_4: addEventImageVideo4,
            event_image_video_5: addEventImageVideo5,

            event_url: addEventUrl,
            event_redirect: addEventRedirect,
            event_color: addEventColor,
            event_key_words: addEventKeyWords,
            priority:addEventPriority,
         }),
         credentials: 'include',
      });
      if (response.ok) {
         window.location.reload(false);
      }
      else {
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   };

   const delete_event = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa stergi evenimentul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/portofolio?uuid=' + e.target.value, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            window.location.reload(false);
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const update_event = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa modifici evenimentul?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/portofolio?uuid=' + e.target.value, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               event_type: eventType[e.target.value],
               event_date: eventDate[e.target.value],
               event_location: eventLocation[e.target.value],
               event_name: eventName[e.target.value],
               event_description: eventDescription[e.target.value],
               event_subdescription: eventSubDescription[e.target.value],

               event_image_video_1: eventImageVideo1[e.target.value],
               event_image_video_2: eventImageVideo2[e.target.value],
               event_image_video_3: eventImageVideo3[e.target.value],
               event_image_video_4: eventImageVideo4[e.target.value],
               event_image_video_5: eventImageVideo5[e.target.value],

               event_url: eventUrl[e.target.value],
               event_redirect: eventRedirect[e.target.value],
               event_color: eventColor[e.target.value],
               event_key_words: eventKeyWords[e.target.value],
               priority:eventPriority[e.target.value],
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Eveniment modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeAdd = (e, type, content) => {

      if (type === "TypeEventAdd") {
         const { value } = e.target;
         setAddEventType(value);
      }
      else if (type === "DateAdd") {
         const { value } = e.target;
         setAddEventDate(value);
      }
      else if (type === "LocationAdd") {
         const { value } = e.target;
         setAddEventLocatio(value);
      }
      else if (type === "TitleAdd") {
         const { value } = e.target;
         setAddEventName(value);
      }
      else if (type === "DescriptionAdd") {
         setAddEventDescription(content);
      }
      else if (type === "SubDescriptionAdd") {
         setAddEventSubDescription(content);
      }

      else if (type === "ImageVideo1Add") {
         const { value } = e.target;
         setAddEventImageVideo1(value);
      }
      else if (type === "ImageVideo2Add") {
         const { value } = e.target;
         setAddEventImageVideo2(value);
      }
      else if (type === "ImageVideo3Add") {
         const { value } = e.target;
         setAddEventImageVideo3(value);
      }
      else if (type === "ImageVideo4Add") {
         const { value } = e.target;
         setAddEventImageVideo4(value);
      }
      else if (type === "ImageVideo5Add") {
         const { value } = e.target;
         setAddEventImageVideo5(value);
      }
      else if (type === "RedirectAdd") {
         const { value } = e.target;
         setAddEventRedirect(value);
      }

      else if (type === "UrlAdd") {
         const { value } = e.target;
         setAddEventUrl(value);
      }
      else if (type === "ColorAdd") {
         const { value } = e.target;
         setAddEventColor(value);
      }
      else if (type === "PriorityAdd") {
         const { value } = e.target;
         setAddEventPriority(value);
      }
      else if (type === "KeyWordsAdd") {
         const { value } = e.target;
         setAddEventKeyWords(value);
      }
   };

   const handleChangeStatic = (e, uuid, type, content) => {

      if (type === "TypeEventAdd") {
         const { value } = e.target;
         eventType[uuid] = value;
      }
      if (type === "DateAdd") {
         const { value } = e.target;
         eventDate[uuid] = value;
      }
      else if (type === "LocationAdd") {
         const { value } = e.target;
         eventLocation[uuid] = value;
      }
      else if (type === "TitleAdd") {
         const { value } = e.target;
         eventName[uuid] = value;
      }
      else if (type === "DescriptionAdd") {
         eventDescription[uuid] = content;
      }
      else if (type === "SubDescriptionAdd") {
         eventSubDescription[uuid] = content;
      }

      else if (type === "ImageVideo1Add") {
         const { value } = e.target;
         eventImageVideo1[uuid] = value;
      }
      else if (type === "ImageVideo2Add") {
         const { value } = e.target;
         eventImageVideo2[uuid] = value;
      }
      else if (type === "ImageVideo3Add") {
         const { value } = e.target;
         eventImageVideo3[uuid] = value;
      }
      else if (type === "ImageVideo4Add") {
         const { value } = e.target;
         eventImageVideo4[uuid] = value;
      }
      else if (type === "ImageVideo5Add") {
         const { value } = e.target;
         eventImageVideo5[uuid] = value;
      }
      else if (type === "RedirectAdd") {
         const { value } = e.target;
         eventRedirect[uuid] = value;
      }

      else if (type === "UrlAdd") {
         const { value } = e.target;
         eventUrl[uuid] = value;
      }
      else if (type === "ColorAdd") {
         const { value } = e.target;
         eventColor[uuid] = value;
      }
      else if (type === "PriorityAdd") {
         const { value } = e.target;
         eventPriority[uuid] = value;
      }
      else if (type === "KeyWordsAdd") {
         const { value } = e.target;
         eventKeyWords[uuid] = value;
      }

   };

   const change_key_words = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      dataContentPage && dataContentPage.map((obiect, index) => (
         obiect["key_cp"] === "key_words_portofolio" && (
            uuid = obiect["uuid"]
         )
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: keyWords,
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleChangeKeyWords = (e, uuid) => {
      const { value } = e.target;
      setKeyWords(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Portofoliu</h1>
               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Modifica cuvinte cheie portofoliu principal</h3>
                  <form onSubmit={change_key_words}>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie:</p>
                        <input type="text" name="url" defaultValue={keyWords} onChange={(e) => handleChangeKeyWords(e)} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica cuvinte cheie</button>
                     </div>
                  </form>
               </div>

               <hr />

               <div className={styles_static.zone_add}>
                  <h3>Adauga un eveniment</h3>
                  <form onSubmit={add_event}>
                     <div className={styles_static.row}>
                        <p>Tip eveniment:</p>
                        <div className={styles.divFormSpecial}>
                           <div className={styles.divFormSpecialChild}>
                              <label htmlFor="html">Weeding</label>
                              <input type="radio" name="radio" value="wedding" onChange={(e) => handleChangeAdd(e, "TypeEventAdd")} defaultChecked />
                           </div>

                           <div className={styles.divFormSpecialChild}>
                              <label htmlFor="html">Christening</label>
                              <input type="radio" name="radio" value="christening" onChange={(e) => handleChangeAdd(e, "TypeEventAdd")} />
                           </div>

                           <div className={styles.divFormSpecialChild}>
                              <label htmlFor="html">Commercials</label>
                              <input type="radio" name="radio" value="commercials" onChange={(e) => handleChangeAdd(e, "TypeEventAdd")} />
                           </div>

                           <div className={styles.divFormSpecialChild}>
                              <label htmlFor="html">Proiecte personale</label>
                              <input type="radio" name="radio" value="proiecte-personale" onChange={(e) => handleChangeAdd(e, "TypeEventAdd")} />
                           </div>
                        </div>
                     </div>

                     <div className={styles_static.row}>
                        <p>Locatie eveniment:</p>
                        <input type="text" name="type" defaultValue={addEventLocation} onChange={(e) => handleChangeAdd(e, "LocationAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Titlu eveniment:</p>
                        <input type="text" name="type" defaultValue={addEventName} onChange={(e) => handleChangeAdd(e, "TitleAdd")} />
                     </div>

                     <div className={styles.rowInputSpecial}>
                        <p>SubDescriere eveniment:</p>
                        <Editor
                           apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                           init={{
                              plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                              mewnubar: "insert",
                              toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                              nonbreaking_force_tab: true
                           }}
                           value={addEventSubDescription}
                           onEditorChange={(content) => handleChangeAdd(undefined, "SubDescriptionAdd", content)}
                        />
                     </div>

                     <div className={styles.rowInputSpecial}>
                        <p>Descriere eveniment:</p>
                        <Editor
                           apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                           init={{
                              plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                              mewnubar: "insert",
                              toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                              nonbreaking_force_tab: true
                           }}
                           value={addEventDescription}
                           onEditorChange={(content) => handleChangeAdd(undefined, "DescriptionAdd", content)}
                        />
                     </div>

                     <hr />

                     <div className={styles_static.row}>
                        <p>Url imagine eveniment (obligatoriu imagine!):</p>
                        <input type="text" name="type" defaultValue={addEventImageVideo1} onChange={(e) => handleChangeAdd(e, "ImageVideo1Add")} required/>
                     </div>
                     <div className={styles_static.row}>
                        <p>Url imagine/video 1 eveniment:</p>
                        <input type="text" name="type" defaultValue={addEventImageVideo2} onChange={(e) => handleChangeAdd(e, "ImageVideo2Add")} />
                     </div>

                     <hr />

                     <div className={styles_static.row}>
                        <p>Url eveniment:</p>
                        <input type="text" name="name" defaultValue={addEventUrl} onChange={(e) => handleChangeAdd(e, "UrlAdd")} required/>
                     </div>
                     <div className={styles_static.row}>
                        <p>Url pentru redirectionare eveniment:</p>
                        <input type="text" name="name" defaultValue={addEventRedirect} onChange={(e) => handleChangeAdd(e, "RedirectAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Culoare:</p>
                        <input type="color" defaultValue={addEventColor} onChange={(e) => handleChangeAdd(e, "ColorAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Prioritate:</p>
                        <input type="number" min="0" max="9999999" name="name" defaultValue={addEventPriority} onChange={(e) => handleChangeAdd(e, "PriorityAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie subpagina:</p>
                        <input type="text" name="name" defaultValue={addEventKeyWords} onChange={(e) => handleChangeAdd(e, "KeyWordsAdd")} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga eveniment</button>
                     </div>
                  </form>
               </div>

               <div className={styles.cards}>
                  {
                     data && data.sort((a, b) => b.id - a.id).map((object, key) => {
                        eventType[object["uuid"]] = object["event_type"];
                        eventDate[object["uuid"]] = object["event_date"];
                        eventLocation[object["uuid"]] = object["event_location"];
                        eventName[object["uuid"]] = object["event_name"];
                        eventDescription[object["uuid"]] = object["event_description"];
                        eventSubDescription[object["uuid"]] = object["event_subdescription"];

                        eventImageVideo1[object["uuid"]] = object["event_image_video_1"];
                        eventImageVideo2[object["uuid"]] = object["event_image_video_2"];
                        eventImageVideo3[object["uuid"]] = object["event_image_video_3"];
                        eventImageVideo4[object["uuid"]] = object["event_image_video_4"];
                        eventImageVideo5[object["uuid"]] = object["event_image_video_5"];

                        eventUrl[object["uuid"]] = object["event_url"];
                        eventRedirect[object["uuid"]] = object["event_redirect"];
                        eventColor[object["uuid"]] = object["event_color"];
                        eventPriority[object["uuid"]] = object["priority"];
                        eventKeyWords[object["uuid"]] = object["event_key_words"];

                        return (
                           <div key={key} className={styles.card} >
                              <div className={styles.row}>
                                 <p>UUID:</p>
                                 <input value={object["uuid"]} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Creat de:</p>
                                 <input value={object["added_by"]} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Modificat de:</p>
                                 <input value={object["modified_by"]} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Creat pe data:</p>
                                 <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Modificat pe data:</p>
                                 <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                              </div>
                              <div className={styles.row}>
                                 <p>Tip eveniment:</p>
                                 <div className={styles.divFormSpecial}>
                                    <div className={styles.divFormSpecialChild}>
                                       <label htmlFor="html">Weeding</label>
                                       <input type="radio" name={"radio " + key} value="wedding" defaultChecked={eventType[object["uuid"]] === "wedding"} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeEventAdd")} />
                                    </div>

                                    <div className={styles.divFormSpecialChild}>
                                       <label htmlFor="html">Christening</label>
                                       <input type="radio" name={"radio " + key} value="christening" defaultChecked={eventType[object["uuid"]] === "christening"} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeEventAdd")} />
                                    </div>

                                    <div className={styles.divFormSpecialChild}>
                                       <label htmlFor="html">Commercials</label>
                                       <input type="radio" name={"radio " + key} value="commercials" defaultChecked={eventType[object["uuid"]] === "commercials"} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeEventAdd")} />
                                    </div>

                                    <div className={styles.divFormSpecialChild}>
                                       <label htmlFor="html">Proiecte personale</label>
                                       <input type="radio" name={"radio " + key} value="proiecte-personale" defaultChecked={eventType[object["uuid"]] === "proiecte-personale"} onChange={(e) => handleChangeStatic(e, object["uuid"], "TypeEventAdd")} />
                                    </div>
                                 </div>
                              </div>
                              <div className={styles.row + " " + styles.rowInputSpecial}>
                                 <p>Locatie eveniment:</p>
                                 <input type="text" defaultValue={eventLocation[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "LocationAdd")} />
                              </div>
                              <div className={styles.row}>
                                 <p>Titlu eveniment:</p>
                                 <input type="text" defaultValue={eventName[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "TitleAdd")} />
                              </div>
                              <Collapsible trigger="CLICK AICI pentru a vedea/ascunde detaliile!" className={styles.collapsible}>
                                 
                                 <div className={styles.rowInputSpecial}>
                                    <p>SubDescriere eveniment</p>
                                    <br />
                                    <Editor
                                       apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                                       init={{
                                          plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                          mewnubar: "insert",
                                          toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                          nonbreaking_force_tab: true
                                       }}
                                       initialValue={eventSubDescription[object["uuid"]]}
                                       onEditorChange={(content) => handleChangeStatic(undefined, object["uuid"], "SubDescriptionAdd", content)}
                                    />
                                 </div>
                                 
                                 <br />
                                 
                                 <div className={styles.rowInputSpecial}>
                                    <p>Descriere eveniment</p>
                                    <br />
                                    <Editor
                                       apiKey='p2t31nruod894tpnwrvlxzyi2r4w6zwgcgazm3xchvezh44i'
                                       init={{
                                          plugins: 'nonbreaking anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                          mewnubar: "insert",
                                          toolbar: 'nonbreaking | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                          nonbreaking_force_tab: true
                                       }}
                                       initialValue={eventDescription[object["uuid"]]}
                                       onEditorChange={(content) => handleChangeStatic(undefined, object["uuid"], "DescriptionAdd", content)}
                                    />
                                 </div>  

                                 <hr />

                                 <div className={styles.row}>
                                    <p>Url img 1 (obligatoriu imagine!):</p>
                                    <input type="text" defaultValue={eventImageVideo1[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "ImageVideo1Add")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Url img/video 2:</p>
                                    <input type="text" defaultValue={eventImageVideo2[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "ImageVideo2Add")} />
                                 </div>

                                 <hr />

                                 <div className={styles.row}>
                                    <p>Url eveniment:</p>
                                    <input type="text" defaultValue={eventUrl[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "UrlAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Url redirectionare:</p>
                                    <input type="text" defaultValue={eventRedirect[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "RedirectAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Cuvinte cheie subpagina:</p>
                                    <input type="text" defaultValue={eventKeyWords[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "KeyWordsAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Culoare:</p>
                                    <input type="color" defaultValue={eventColor[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "ColorAdd")} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Prioritate:</p>
                                    <input type="number" min="0" max="9999999" defaultValue={eventPriority[object["uuid"]]} onChange={(e) => handleChangeStatic(e, object["uuid"], "PriorityAdd")} />
                                 </div>
                              </ Collapsible>
                              <br />
                              <div className={styles.row + " " + styles.rowButtonSpecial}>
                                 <button onClick={update_event} value={object["uuid"]}>Modifica eveniment</button>
                                 <button onClick={delete_event} value={object["uuid"]}>Sterge eveniment</button>
                              </div>
                           </div>
                        );
                     })
                  }
               </div>

            </div>

         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Portofolio;