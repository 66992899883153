import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Offer.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { FaHouse } from "react-icons/fa6";

import { notify_error, notify_warn, notify_success, notify } from '../utils/notify'

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Offer = (props) => {

   const [data, setData] = useState(null);
   const [isLoading, setIsLoading] = useState(false);

   const [keyCp, setKeyCp] = useState({});
   const [valueCp, setValueCp] = useState({});


   const [addpackage1, setAddpackage1] = useState("");
   const [addpackage1Id, setAddpackage1Id] = useState("1");

   const [addpackage2, setAddpackage2] = useState("");
   const [addpackage2Id, setAddpackage2Id] = useState("1");

   const [addpackage3, setAddpackage3] = useState("");
   const [addpackage3Id, setAddpackage3Id] = useState("1");

   const [addpackage4, setAddpackage4] = useState("");
   const [addpackage4Id, setAddpackage4Id] = useState("1");

   const [addUrlImage, setAddUrlImage] = useState("");
   const [addUrlImageID, setAddUrlImageId] = useState("");

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if (await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "offer_image" && (
                  setAddUrlImage(obiect["value_cp"]),
                  setAddUrlImageId(obiect["uuid"])
               )
            ))

            setData(jsonData);

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);


   const add_value_package = async (e, type) => {
      setIsLoading(true);

      e.preventDefault();

      var Id = "", description = "";

      if (type === 1) {
         Id = addpackage1Id;
         description = addpackage1;
      }
      else if (type === 2) {
         Id = addpackage2Id;
         description = addpackage2;
      }
      else if (type === 3) {
         Id = addpackage3Id;
         description = addpackage3;
      }
      else if (type === 4) {
         Id = addpackage4Id;
         description = addpackage4;
      }

      const response = await fetch(BACKEND_URL + '/content_page?', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            key_cp: "home_package" + type + "_" + Id,
            value_cp: description,
            is_lock: "0"
         }),
         credentials: 'include',
      });
      if (response.ok) {
         window.location.reload(false);
      }
      else {
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   };

   const delete_value_package = async (e) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa stergi valoarea?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + e.target.value, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            window.location.reload(false);
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const update_value_package = async (e, type) => {
      setIsLoading(true);

      var answer = window.confirm("Esti sigur ca vrei sa modifici valoarea?");
      if (answer) {
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + e.target.value, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               key_cp: "home_package" + type + "_" + keyCp[e.target.value],
               value_cp: valueCp[e.target.value],
            }),
            credentials: 'include',
         });
         if (response.ok) {
            notify_success("Oferta modificat cu succes!");
         }
         else {
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const update_image = async (e, type) => {
      setIsLoading(true);

      e.preventDefault();

      const response = await fetch(BACKEND_URL + '/content_page?uuid=' + addUrlImageID, {
         method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            value_cp: addUrlImage
         }),
         credentials: 'include',
      });
      if (response.ok) {
         notify_success("Imagine oferta modificata cu succes!");
      }
      else {
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   };

   const handleAddPackage = (e, type) => {
      if (type === "1") {
         const { value } = e.target;
         setAddpackage1(value);
      }
      else if (type === "2") {
         const { value } = e.target;
         setAddpackage2(value);
      }
      else if (type === "3") {
         const { value } = e.target;
         setAddpackage3(value);
      }
      else if (type === "4") {
         const { value } = e.target;
         setAddpackage4(value);
      }
   };

   const handleAddPackageId = (e, type) => {
      if (type === "1") {
         const { value } = e.target;
         setAddpackage1Id(value);
      }
      else if (type === "2") {
         const { value } = e.target;
         setAddpackage2Id(value);
      }
      else if (type === "3") {
         const { value } = e.target;
         setAddpackage3Id(value);
      }
      else if (type === "4") {
         const { value } = e.target;
         setAddpackage4Id(value);
      }
   };

   const handleStaticPackage = (e, uuid) => {
      const { value } = e.target;
      valueCp[uuid] = value;
   };

   const handleStaticPackageId = (e, uuid) => {
      const { value } = e.target;
      keyCp[uuid] = value;
   };

   const handleAddUrlImage = (e) => {
      const { value } = e.target;
      setAddUrlImage(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }

         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Oferta</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Adauga date pachet 1</h3>
                  <form onSubmit={(e) => add_value_package(e, 1)}>
                     <div className={styles_static.row}>
                        <p>Id:</p>
                        <input type="number" min="1" max="1000" name="type" defaultValue={addpackage1Id} onChange={(e) => handleAddPackageId(e, "1")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Descriere:</p>
                        <input type="text" name="type" defaultValue={addpackage1} onChange={(e) => handleAddPackage(e, "1")} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga valoare pachet 1</button>
                     </div>
                  </form>
               </div>

               <div className={styles_static.zone_add}>
                  <h3>Adauga date pachet 2</h3>
                  <form onSubmit={(e) => add_value_package(e, 2)}>
                     <div className={styles_static.row}>
                        <p>Id:</p>
                        <input type="number" min="1" max="1000" name="type" defaultValue={addpackage2Id} onChange={(e) => handleAddPackageId(e, "2")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Descriere:</p>
                        <input type="text" name="type" defaultValue={addpackage2} onChange={(e) => handleAddPackage(e, "2")} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga valoare pachet 2</button>
                     </div>
                  </form>
               </div>

               <div className={styles_static.zone_add}>
                  <h3>Adauga date pachet 3</h3>
                  <form onSubmit={(e) => add_value_package(e, 3)}>
                     <div className={styles_static.row}>
                        <p>Id:</p>
                        <input type="number" min="1" max="1000" name="type" defaultValue={addpackage3Id} onChange={(e) => handleAddPackageId(e, "3")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Descriere:</p>
                        <input type="text" name="type" defaultValue={addpackage3} onChange={(e) => handleAddPackage(e, "3")} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga valoare pachet 3</button>
                     </div>
                  </form>
               </div>
               <div className={styles_static.zone_add}>
                  <h3>Adauga date pachet 4</h3>
                  <form onSubmit={(e) => add_value_package(e, 4)}>
                     <div className={styles_static.row}>
                        <p>Id:</p>
                        <input type="number" min="1" max="1000" name="type" defaultValue={addpackage4Id} onChange={(e) => handleAddPackageId(e, "4")} />
                     </div>
                     <div className={styles_static.row}>
                        <p>Descriere:</p>
                        <input type="text" name="type" defaultValue={addpackage4} onChange={(e) => handleAddPackage(e, "4")} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga valoare pachet 4</button>
                     </div>
                  </form>
               </div>

               <div className={styles_static.zone_add}>
                  <h3>Url imagine</h3>
                  <form onSubmit={(e) => update_image(e)}>
                     <div className={styles_static.row}>
                        <p>url:</p>
                        <input type="text" name="type" defaultValue={addUrlImage} onChange={(e) => handleAddUrlImage(e)} />
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga url imagine</button>
                     </div>
                  </form>
               </div>

               <h2>Pachet 1</h2>
               <div className={styles.cards}>
                  {
                     data && data.sort((a, b) => {
                        const valueA = a["key_cp"];
                        const valueB = b["key_cp"];
                        return valueA.localeCompare(valueB);
                     }).map((object, key) => {
                        if (object["key_cp"].includes("home_package1_")) {
                           keyCp[object["uuid"]] = object["key_cp"].substring(object["key_cp"].lastIndexOf('_') + 1);
                           valueCp[object["uuid"]] = object["value_cp"];

                           return (
                              <div key={key} className={styles.card} >
                                 <div className={styles.row}>
                                    <p>UUID:</p>
                                    <input value={object["uuid"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat de:</p>
                                    <input value={object["added_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat de:</p>
                                    <input value={object["modified_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat pe data:</p>
                                    <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat pe data:</p>
                                    <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>ID:</p>
                                    <input type="number" min="1" max="1000" defaultValue={keyCp[object["uuid"]]} onChange={(e) => handleStaticPackageId(e, object["uuid"])} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Valoare:</p>
                                    <input type="text" defaultValue={valueCp[object["uuid"]]} onChange={(e) => handleStaticPackage(e, object["uuid"])} />
                                 </div>
                                 <br />
                                 <div className={styles.row}>
                                    <button onClick={(e) => update_value_package(e, "1")} value={object["uuid"]}>Modifica valoare pachet 1</button>
                                    <button onClick={delete_value_package} value={object["uuid"]}>Sterge valoare pachet 1</button>
                                 </div>
                              </div>
                           );
                        }
                     })
                  }
               </div>

               <hr />

               <h2>Pachet 2</h2>
               <div className={styles.cards}>
                  {
                     data && data.sort((a, b) => {
                        const valueA = a["key_cp"];
                        const valueB = b["key_cp"];
                        return valueA.localeCompare(valueB);
                     }).map((object, key) => {
                        if (object["key_cp"].includes("home_package2_")) {
                           keyCp[object["uuid"]] = object["key_cp"].substring(object["key_cp"].lastIndexOf('_') + 1);
                           valueCp[object["uuid"]] = object["value_cp"];

                           return (
                              <div key={key} className={styles.card} >
                                 <div className={styles.row}>
                                    <p>UUID:</p>
                                    <input value={object["uuid"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat de:</p>
                                    <input value={object["added_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat de:</p>
                                    <input value={object["modified_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat pe data:</p>
                                    <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat pe data:</p>
                                    <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>ID:</p>
                                    <input type="number" min="1" max="1000" defaultValue={keyCp[object["uuid"]]} onChange={(e) => handleStaticPackageId(e, object["uuid"])} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Valoare:</p>
                                    <input type="text" defaultValue={valueCp[object["uuid"]]} onChange={(e) => handleStaticPackage(e, object["uuid"])} />
                                 </div>
                                 <br />
                                 <div className={styles.row}>
                                    <button onClick={(e) => update_value_package(e, "2")} value={object["uuid"]}>Modifica valoare pachet 2</button>
                                    <button onClick={delete_value_package} value={object["uuid"]}>Sterge valoare pachet 2</button>
                                 </div>
                              </div>
                           );
                        }
                     })
                  }
               </div>

               <hr />

               <h2>Pachet 3</h2>
               <div className={styles.cards}>
                  {
                     data && data.sort((a, b) => {
                        const valueA = a["key_cp"];
                        const valueB = b["key_cp"];
                        return valueA.localeCompare(valueB);
                     }).map((object, key) => {
                        if (object["key_cp"].includes("home_package3_")) {
                           keyCp[object["uuid"]] = object["key_cp"].substring(object["key_cp"].lastIndexOf('_') + 1);
                           valueCp[object["uuid"]] = object["value_cp"];

                           return (
                              <div key={key} className={styles.card} >
                                 <div className={styles.row}>
                                    <p>UUID:</p>
                                    <input value={object["uuid"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat de:</p>
                                    <input value={object["added_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat de:</p>
                                    <input value={object["modified_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat pe data:</p>
                                    <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat pe data:</p>
                                    <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>ID:</p>
                                    <input type="number" min="1" max="1000" defaultValue={keyCp[object["uuid"]]} onChange={(e) => handleStaticPackageId(e, object["uuid"])} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Valoare:</p>
                                    <input type="text" defaultValue={valueCp[object["uuid"]]} onChange={(e) => handleStaticPackage(e, object["uuid"])} />
                                 </div>
                                 <br />
                                 <div className={styles.row}>
                                    <button onClick={(e) => update_value_package(e, "3")} value={object["uuid"]}>Modifica valoare pachet 3</button>
                                    <button onClick={delete_value_package} value={object["uuid"]}>Sterge valoare pachet 3</button>
                                 </div>
                              </div>
                           );
                        }
                     })
                  }
               </div>

               <hr />

               <h2>Pachet 4</h2>
               <div className={styles.cards}>
                  {
                     data && data.sort((a, b) => {
                        const valueA = a["key_cp"];
                        const valueB = b["key_cp"];
                        return valueA.localeCompare(valueB);
                     }).map((object, key) => {
                        if (object["key_cp"].includes("home_package4_")) {
                           keyCp[object["uuid"]] = object["key_cp"].substring(object["key_cp"].lastIndexOf('_') + 1);
                           valueCp[object["uuid"]] = object["value_cp"];

                           return (
                              <div key={key} className={styles.card} >
                                 <div className={styles.row}>
                                    <p>UUID:</p>
                                    <input value={object["uuid"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat de:</p>
                                    <input value={object["added_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat de:</p>
                                    <input value={object["modified_by"]} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Creat pe data:</p>
                                    <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Modificat pe data:</p>
                                    <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy hh.mm.ss') : null} disabled />
                                 </div>
                                 <div className={styles.row}>
                                    <p>ID:</p>
                                    <input type="number" min="1" max="1000" defaultValue={keyCp[object["uuid"]]} onChange={(e) => handleStaticPackageId(e, object["uuid"])} />
                                 </div>
                                 <div className={styles.row}>
                                    <p>Valoare:</p>
                                    <input type="text" defaultValue={valueCp[object["uuid"]]} onChange={(e) => handleStaticPackage(e, object["uuid"])} />
                                 </div>
                                 <br />
                                 <div className={styles.row}>
                                    <button onClick={(e) => update_value_package(e, "4")} value={object["uuid"]}>Modifica valoare pachet 4</button>
                                    <button onClick={delete_value_package} value={object["uuid"]}>Sterge valoare pachet 4</button>
                                 </div>
                              </div>
                           );
                        }
                     })
                  }
               </div>


            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Offer;