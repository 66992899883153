import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from './Cookie';
import BACKEND_URL from '../utils/env';

export const checkAuth = async () => {
   const response = await fetch(BACKEND_URL + '/me', {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
      credentials: 'include',
   });

   if (!response.ok) {
      deleteCookie('auth_token');
      return false;
   }
   else return true;
};