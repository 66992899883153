import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

import { FaHouse } from "react-icons/fa6";


import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Users.module.css';
import 'react-toastify/dist/ReactToastify.css';

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Users = (props) => {

   const [data, setData] = useState(null);
   const[isLoading, setIsLoading] = useState(false);

   const [dataUsers, setDataUsers] = useState(null);
   const [emails, setEmails] = useState({});
   const [usernames, setUsernames] = useState({});

   const [userAddEmail, setUserAddEmail] = useState(undefined);
   const [userAddUsername, setUserAddUsername] = useState(undefined);

   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();

      const checkToken = async () => {
         const response = await fetch(BACKEND_URL + '/me', {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (!response.ok) {
            navigate("/");
         }
         else{
            const jsonData = await response.json();
            setData(jsonData);
         }
      };
      checkToken();


      const getUsers = async () => {
         const response = await fetch(BACKEND_URL + '/users', {
            method: 'GET',
            headers: {
               'Content-Type': 'application/json',
            },
            credentials: 'include',
         });
         if (response.ok) {
            const jsonData = await response.json();
            setDataUsers(jsonData);
         }
      };
      getUsers();

      setIsLoading(false);
   }, []);

   const delete_user = async (e) => {
      setIsLoading(true);

      if(data && data["uuid"] === e.target.value){
         window.alert("Nu poti sterge contul tau!");
      }
      else{
         var answer = window.confirm("Esti sigur ca vrei sa stergi utilizatorul?");
         if(answer){
            const response = await fetch(BACKEND_URL + '/users?uuid=' + e.target.value, {
               method: 'DELETE',
               headers: {
                  'Content-Type': 'application/json',
               },
               credentials: 'include',
            });
            if (response.ok){
               window.location.reload(false);
            }
            else{
               notify_error("Ceva nu a mers bine!");
            }
         }
      }

      setIsLoading(false);
   };

   const update_user = async (e) => {
      setIsLoading(true);

      if(data && data["uuid"] === e.target.value){
         window.alert("Nu poti edita contul tau de aici!");
      }
      else{
         var answer = window.confirm("Esti sigur ca vrei sa modifici utilizatorul?");
         if(answer){
            const response = await fetch(BACKEND_URL + '/users?uuid=' + e.target.value, {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                  email: emails[e.target.value],
                  username: usernames[e.target.value],
               }),
               credentials: 'include',
            });
            if (response.ok){
               notify_success("Utilizator modificat cu succes!");
            }
            else{
               notify_error("Ceva nu a mers bine!");
            }
         }
      }

      setIsLoading(false);
   };

   const resetPassword_user = async (e) =>{
      setIsLoading(true);

      if(data && data["uuid"] === e.target.value){
         window.alert("Nu poti edita contul tau de aici!");
      }
      else{
         var answer = window.confirm("Esti sigur ca vrei sa resetezi parola utilizatorului?");
         let randomPassword = (Math.random() + 1).toString(36).substring(7);
         if(answer){
            const response = await fetch(BACKEND_URL + '/users?uuid=' + e.target.value, {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                  email: emails[e.target.value],
                  password: randomPassword,
               }),
               credentials: 'include',
            });
            if (response.ok){
               notify_success("Parola resetata cu succes!");
            }
            else{
               notify_error("Ceva nu a mers bine!");
            }
         }
      }

      setIsLoading(false);
   }

   const add_user = async (e) =>{
      setIsLoading(true);

      e.preventDefault();
      const response = await fetch(BACKEND_URL + '/users', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            email: userAddEmail,
            username: userAddUsername,
         }),
         credentials: 'include',
      });
      if (response.ok){
         window.location.reload(false);
      }
      else{
         notify_error("Ceva nu a mers bine!");
      }

      setIsLoading(false);
   }

   const handleChangeEmail = (e, uuid) => {
      const { value } = e.target;
      emails[uuid] = value;
   };
   
   const handleChangeUsername = (e, uuid) => {
      const { value } = e.target;
      usernames[uuid] = value;
   };
   
   const handleChangeEmailAdd = (e, uuid) => {
      const { value } = e.target;
      setUserAddEmail(value);
   };

   const handleChangeUsernameAdd = (e, uuid) => {
      const { value } = e.target;
      setUserAddUsername(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Utilizatori</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Adauga Utilizator</h3>
                  <form onSubmit={add_user}>
                     <div className={styles_static.row}>
                        <p>E-mail:</p>
                        <input type="email" name="email" defaultValue={userAddEmail} onChange={(e) => handleChangeEmailAdd(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <p>Username:</p>
                        <input type="username" name="username" defaultValue={userAddUsername} onChange={(e) => handleChangeUsernameAdd(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Adauga utilizator</button>
                     </div>
                  </form>
               </div>
               <div className={styles.cards}>
                  {
                     dataUsers && dataUsers.map((object, key) => {
                        emails[object["uuid"]] = object["email"];
                        usernames[object["uuid"]] = object["username"];
                        
                        return (
                           <div key={key} className={styles.card} >
                              <div className={styles.row}>
                                 <p>UUID:</p>
                                 <input value={object["uuid"]} disabled/>
                              </div>
                              <div className={styles.row}>
                                 <p>E-mail:</p>
                                 <input type="email" defaultValue={emails[object["uuid"]]} name="email" onChange={(e) => handleChangeEmail(e, object["uuid"])}/>
                              </div>
                              <div className={styles.row}>
                                 <p>Username:</p>
                                 <input type="username" defaultValue={usernames[object["uuid"]]} name="username" onChange={(e) => handleChangeUsername(e, object["uuid"])}/>
                              </div>
                              <div className={styles.row}>
                                 <p>Creat pe data:</p>
                                 <input value={object["created_on"] ? format(new Date(object["created_on"]), 'dd.MM.yyyy') : null} disabled/>
                              </div>
                              <div className={styles.row}>
                                 <p>Ultima modificare:</p>
                                 <input value={object["last_modification"] ? format(new Date(object["last_modification"]), 'dd.MM.yyyy') : null} disabled/>
                              </div>
                              <div className={styles.row}>
                                 <p>Ultima autentificare:</p>
                                 <input value={object["last_login"] ? format(new Date(object["last_login"]), 'dd.MM.yyyy') : null} disabled/>
                              </div>

                              <br />
                              <div className={styles.row}>
                                 <button onClick={update_user} value={object["uuid"]}>Modifica utilizator</button>
                                 <button onClick={delete_user} value={object["uuid"]}>Sterge utilizator</button>
                                 <button onClick={resetPassword_user} value={object["uuid"]}>Reseteaza parola</button>
                              </div>
                           </div>
                        );
                     })
                  }
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Users;