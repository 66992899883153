import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

import styles_static from '../styles/Static.module.css';
import styles from '../styles/Home.module.css';
import 'react-toastify/dist/ReactToastify.css';
import {notify_error, notify_warn, notify_success, notify} from '../utils/notify'
import { FaHouse } from "react-icons/fa6";

import BACKEND_URL from '../utils/env';

import { checkAuth } from '../utils/check_auth';

import Loading from '../utils/loading';

const Home = (props) => {

   const [data, setData] = useState(null);
   const [videoError, setVideoError] = useState(false);
   const [homeUrl, setHomeUrl] = useState(undefined);
   const [logoUrl, setLogoUrl] = useState(undefined);
   const [keyWords, setKeyWords] = useState(undefined);
   const[isLoading, setIsLoading] = useState(false);
   
   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);

      const loadUtilsCheckAuth = async () => {
         if(await checkAuth() === false)
            navigate('/');
      }
      loadUtilsCheckAuth();
      
      const fetchData = async () => {
         try {
            const response = await fetch(BACKEND_URL + '/content_page');
            const jsonData = await response.json();
            setData(jsonData);

            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "home_url" && (
                  setHomeUrl(obiect["value_cp"])
               ) 
            ))
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "website-logo" && (
                  setLogoUrl(obiect["value_cp"])
               ) 
            ))
            
            jsonData && jsonData.map((obiect, index) => (
               obiect["key_cp"] === "key_words_home" && (
                  setKeyWords(obiect["value_cp"])
               ) 
            ))

         } catch (error) {
            console.error('Eroare:', error);
         }
      };
      fetchData();

      setIsLoading(false);
   }, []);

   const change_url_home = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "home_url" && (
            uuid = obiect["uuid"]
         ) 
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: homeUrl,
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Modificat cu succes!");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };
   
   const change_website_logo = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "website-logo" && (
            uuid = obiect["uuid"]
         ) 
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: logoUrl,
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Modificat cu succes!");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const change_key_words = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      var uuid = "";
      data && data.map((obiect, index) => (
         obiect["key_cp"] === "key_words_home" && (
            uuid = obiect["uuid"]
         ) 
      ))

      var answer = window.confirm("Esti sigur ca vrei sa modifici?");
      if(answer){
         const response = await fetch(BACKEND_URL + '/content_page?uuid=' + uuid, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               value_cp: keyWords,
            }),
            credentials: 'include',
         });
         if (response.ok){
            notify_success("Modificat cu succes!");
         }
         else{
            notify_error("Ceva nu a mers bine!");
         }
      }

      setIsLoading(false);
   };

   const handleVideoError = () => {
      setVideoError(true);
   }

   const handleChangeUrl = (e, uuid) => {
      const { value } = e.target;
      setHomeUrl(value);
   };

   const handleChangeWebsiteLogo = (e, uuid) => {
      const { value } = e.target;
      setLogoUrl(value);
   };

   const handleChangeKeyWords = (e, uuid) => {
      const { value } = e.target;
      setKeyWords(value);
   };

   return (
      <>
         {
            isLoading === true && (
               <Loading />
            )
         }
         <main className={styles_static.main}>
            <div className={styles_static.content}>
               <a href='/dashboard' className={styles_static.titleDashboardA}><FaHouse /><h3 className={styles_static.titleDashboard}>Home</h3></a>
               <h1>Home</h1>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica imagine/video - Home</h3>
                  <form onSubmit={change_url_home}>
                     <div className={styles_static.row}>
                        <p>Url imagine/video:</p>
                        <input type="text" name="url" defaultValue={homeUrl} onChange={(e) => handleChangeUrl(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica url</button>
                     </div>
                     <div className={styles_static.row}>
                     {
                        data && data.map((obiect, index) => (
                           obiect["key_cp"] === "home_url" && obiect["value_cp"] !== "" && (
                              videoError === false ? (
                                 <video autoPlay={true} muted loop={true} controls={false} playsInline disablePictureInPicture disableRemotePlayback onError={handleVideoError}> 
                                    <source  key={index} src={obiect["value_cp"]} type="video/mp4" />
                                 </video>
                              ) :(
                                 <img key={index} src={obiect["value_cp"]} alt=""/>
                              )
                              
                           ) 
                        ))
                     }
                     </div>
                  </form>
               </div>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica url logo</h3>
                  <form onSubmit={change_website_logo}>
                     <div className={styles_static.row}>
                        <p>Url:</p>
                        <input type="text" name="url" defaultValue={logoUrl} onChange={(e) => handleChangeWebsiteLogo(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica url logo</button>
                     </div>
                  </form>
               </div>
               <hr />
               <div className={styles_static.zone_add}>
                  <h3>Modifica cuvinte cheie pagina principala</h3>
                  <form onSubmit={change_key_words}>
                     <div className={styles_static.row}>
                        <p>Cuvinte cheie:</p>
                        <input type="text" name="url" defaultValue={keyWords} onChange={(e) => handleChangeKeyWords(e)}/>
                     </div>
                     <div className={styles_static.row}>
                        <button type='submit'>Modifica cuvinte cheie</button>
                     </div>
                  </form>
               </div>
            </div>
         </main>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
         />
      </>
   );
};

export default Home;